import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/icon/iconfont.css'
import "lib-flexible/flexible"
// 隐藏 右侧浏览器 滚动条
import VueSmoothScroll from "vue2-smooth-scroll"
Vue.use(VueSmoothScroll)
Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')