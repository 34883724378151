<template>
    <div class="head">
        <div class="head-box">
            <el-row style="height:120px">
                <el-col :span="12" class="logoClass">
                    <img src="../assets/img/logo.png" @click="goHome" alt="">
                </el-col>
                <el-col :span="12">
                   <el-row>
                        <el-col class="head-title" v-for="(item,index) in textList" :key="index" :span="item.span">
                            <span :class="[$route.path===item.path?'head-upText':'head-text']" @click="goDetail(item.path)">
                                {{item.text}}
                                <div v-show="$route.path===item.path" class="head-line"></div>
                            </span>
                        </el-col>
                    </el-row> 
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
    export default {
        data(){
            return{
                textList:[
                    {text:'首页',path:'/home',span:4},
                    {text:'公司/品牌简介',path:'/company',span:6},
                    {text:'专利版权',path:'/patent',span:6},
                    {text:'葩钻APP',path:'/application',span:4},
                    {text:'e葩钻',path:'/web',span:4},
                ],
            }
        },
        methods:{
            goDetail(path){
                this.$router.push(path)
            },
            goHome(){
                this.$router.push('/home')
            },
        },
    }
</script>
<style scoped lang="scss">
    .head{
        width: 100%;
        height: 120px;
        background: #EAECF6;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        .logoClass{
            display: flex;
            align-items: center;
            height: 120px;
        }
        &-box{
            width: 1200px;
            margin: 0 auto;
            height: 120px;
        }
        &-title{
            height:120px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
        &-text{
            position: relative;
            display: block;
            padding: 10px 0;
        }
        &-upText{
            position: relative;
            display: block;
            padding: 10px 0;
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #0506AE;
        }
        &-line{
            position: absolute;
            bottom:0;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: #0506AE;
            border-radius: 2px;
        }
    }
</style>